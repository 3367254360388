import React, { useEffect, useState } from "react";
import { Table, Row, Col, Card } from "react-bootstrap";
import styles from "../../_css/SupplierDetails.module.css";
import { ChevronDown, ChevronUp, Dot } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import Paginate from "./Pagination";
import {
  formatContact,
  formatDate,
  getAbvValue,
  getDollarValue,
  getEuroValue,
  getFixedNumber,
  getPoundValue,
} from "../helper/formatters";
import { startLoader, stopLoader } from "../../_store/reducers/loaderReducer";
import { notifyError } from "../helper/Toaster";
import _ from "lodash";
import { getOfferManagementS1 } from "../../requests";
import { TOKEN_ERRORS } from "../../_data/codes";
import handleSessionClear from "../helper/handleSessionClear";
import {
  coloumns,
  salsesColumns,
  sortableColumns,
  widthColoumns,
} from "../../_data/tableHeaders";
import useViewportHeight from "../hooks/useViewPortHeight";

const SupplierDetail = (props) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const {percentageHeight} = useViewportHeight(65);

  const [data, setData] = useState([]);
  const [cheapestAlcohol, setCheapestAlcohol] = useState([]);
  const [supplierInfo, setSupplierInfo] = useState([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    totalPages: 1,
    totalPosts: 0,
  });
  const [sortedColumn, setSortedColumn] = useState({
    column: String,
    sortDsc: true,
  });
  const [perPage, setPerPage] = useState(5);
  const [conversionRate, setConversionRate] = useState({
    dollarForEuro: 0,
    dollarForPound: 0,
    euroForDollar: 0,
    euroForPound: 0,
    poundForDollar: 0,
    poundForEuro: 0,
  });
  const [dataSorting, setDataSorting] = useState({
    date: -1,
  });

  const access_token = localStorage.getItem("access_token_MN");
  const isSales =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 3;

  useEffect(() => {
    if (access_token) {
      getOffers();
    } else {
      Navigate("/login");
    }
  }, [page, perPage, dataSorting]);

  const getNumbericValue = (value) => {
    if (!value) {
      return "-";
    } else if (typeof value === "object") {
      return value.$numberDecimal;
    } else {
      return value;
    }
  };

  const getCheveron = (key) => {
    let lowerKey = _.toLower(key);

    if (lowerKey === "date") lowerKey = "date";
    if (lowerKey === "btls avail") lowerKey = "btlsAvail";
    if (lowerKey === "cs avail") lowerKey = "csAvail";
    if (lowerKey === "price per case(€)") lowerKey = "pricePerCaseEuro";
    if (lowerKey === "price per bottle(€)") lowerKey = "pricePerBottleEuro";

    return (
      <i>
        {Object.keys(dataSorting)[0] === lowerKey ? (
          dataSorting[lowerKey] === -1 ? (
            <ChevronUp size={16} />
          ) : (
            <ChevronDown size={16} />
          )
        ) : (
          <ChevronUp size={16} />
        )}
      </i>
    );
  };


  const handleSort = (key) => {
    let lowerKey = _.toLower(key);

    if (lowerKey === "date") lowerKey = "date";
    if (lowerKey === "btls avail") lowerKey = "btlsAvail";
    if (lowerKey === "cs avail") lowerKey = "csAvail";
    if (lowerKey === "price per case(€)") lowerKey = "pricePerCaseEuro";
    if (lowerKey === "price per bottle(€)") lowerKey = "pricePerBottleEuro";

    setDataSorting((prevValue) => ({
      [lowerKey]: prevValue[lowerKey] === 1 ? -1 : 1,
    }));
    // const lowerKey = _.toLower(key);
    // const previousLowerKey = !_.isEmpty(sortedColumn.column)
    //   ? _.toLower(sortedColumn.column)
    //   : null;
    // let sortedData = data;
    // let coloumnChanged = false;

    // if (previousLowerKey && lowerKey !== previousLowerKey) {
    //   coloumnChanged = true;
    //   sortedData = [...data].sort((a, b) => {
    //     if (a[previousLowerKey] < b[previousLowerKey])
    //       return sortedColumn.sortDsc ? -1 : 1;
    //     if (a[previousLowerKey] > b[previousLowerKey])
    //       return sortedColumn.sortDsc ? 1 : -1;
    //     return 0;
    //   });
    // }

    // const sortingBasis = coloumnChanged ? coloumnChanged : sortedColumn.sortDsc;
    // sortedData = [...sortedData].sort((a, b) => {
    //   if (a[lowerKey] < b[lowerKey]) return sortingBasis ? -1 : 1;
    //   if (a[lowerKey] > b[lowerKey]) return sortingBasis ? 1 : -1;
    //   return 0;
    // });

    // setSortedColumn({ column: key, sortDsc: !sortingBasis });
    // setData(sortedData);
  };

  const handleNext = async (page) => {
    if (page + 1 <= pageInfo.totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = async (page) => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const handleResetPage = () => {
    setPage(1)
  }

  const handlePerPage = (count) => {
    // setPage(1)
    setPerPage(count);
  };

  const getOffers = async () => {
    dispatch(startLoader());

    const sCode = props?.sCode;
    try {
      const payload = {
        page,
        limit: perPage || 5,
        sCode,
        dataSorting
      };
      const response = await getOfferManagementS1(payload);

      if (response.status === 200) {
        // setAuthFalse();
        const pageInfo = {
          totalPages: response.data.totalPages || 1,
          totalPosts: response.data.totalProducts || 0,
        };

        setCheapestAlcohol(response?.data?.cheapestAlcohol);
        setSupplierInfo(response?.data?.supplierInformation);
        setData(response?.data?.products);
        setPageInfo(pageInfo);
        setConversionRate(response?.data?.conversionRate);
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  return (
    <div className="container d-flex flex-column gap-4">
      <Row>
        {/* <Col md={6}> */}
        <Card className={styles.supplierCard}>
          <Card.Body className="p-0">
            <Card.Title className={styles.cardOne}>
              <h4>Supplier Information</h4>
              <span
                className={`${styles["status-supplier"]} ${
                  supplierInfo.length > 0 && supplierInfo[0].onboarded === "Yes"
                    ? styles.onboarded
                    : styles.offboarded
                }`}
              >
                <i className={styles.dot}>
                  <Dot size={24} />
                </i>
                {supplierInfo.length > 0 && supplierInfo[0].onboarded === "Yes"
                  ? "Onboarded"
                  : "offboarded"}
              </span>
            </Card.Title>
            {supplierInfo.length > 0 && (
              <Table className={styles.table} bordered>
                <tbody>
                  <tr>
                    <td>
                      <span className={styles.tableColumns}>Full Name:</span>{" "}
                      {supplierInfo[0].supplierName}
                    </td>
                    <td>
                      <span className={styles.tableColumns}>Company name:</span>{" "}
                      {supplierInfo[0].companyName}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className={styles.tableColumns}>Contact:</span>{" "}
                      {formatContact(supplierInfo[0].contact || 6845550102)}
                    </td>
                    <td>
                      <span className={styles.tableColumns}>
                        Supplier code:
                      </span>{" "}
                      {supplierInfo[0].sCode}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className={styles.tableColumns}>Mail Id:</span>{" "}
                      {supplierInfo[0].email}
                    </td>
                    <td>
                      <span className={styles.tableColumns}>Location:</span>{" "}
                      {supplierInfo[0].location}
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
        {/* </Col> */}
        {/* <Col md={6}>
          <Card className={`${styles.tableContainer} ${styles.cardTwo}`}>
            <Card.Body className="p-0">
              <Card.Title className={styles.cardTwoTitle}>
                Cheapest Alcohols
              </Card.Title>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Size</th>
                    <th>
                      Price <small>(per bottle)</small>
                    </th>
                    <th>Last offer date</th>
                    <th>
                      Availability <small>(cases)</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cheapestAlcohol.map((el, index) => (
                    <tr key={index}>
                      <td>{el.itemName}</td>
                      <td>{el.size}L</td>
                      <td>€{el.pricePerBottleEuro}</td>
                      <td>{formatDate(el.date)}</td>
                      <td>{el.availability}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>Absolut Citron</td>
                    <td>1L</td>
                    <td>€21</td>
                    <td>05/05/2024</td>
                    <td>400</td>
                  </tr>
                  <tr>
                    <td>Brandi</td>
                    <td>75CL</td>
                    <td>€20</td>
                    <td>09/06/2024</td>
                    <td>300</td>
                  </tr> 
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
      <Row>
        <div className={`p-0 ${styles["table-wrapper"]}`}>
          <div className="position-relative">
            <div style={{ maxHeight: percentageHeight }} className={`${styles.tableOffer} overflow-auto table-height-400`}>
              <table className="table">
                <thead>
                  <tr>
                    {(isSales ? salsesColumns : coloumns).map((e, index) => (
                      <th
                        className={`pe-cursor text-center ${
                          e === "Enquiry Status" && "enquiry-status"
                        }`}
                        onClick={() => handleSort(e)}
                        key={index}
                        style={{
                          minWidth: widthColoumns.includes(e)
                            ? "98px"
                            : e === "Enquiry Status" || e === ""
                            ? "100px"
                            : "80px",
                          height: e === "Enquiry Status" && "3.27rem",
                        }}
                      >
                        <div className={`${styles["col-value"]}`}>
                          <span>{e}</span>
                          {sortableColumns.includes(e) && (
                            getCheveron(e)
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} className="pe-cursor">
                      <td className="sticky-left">
                        <div className={styles.statusContainer}>
                          {item.notes.length > 0 ? (
                            <span
                              className={`${styles.status} ${
                                item.notes[item.notes.length - 1].type ===
                                "Purchase"
                                  ? styles["actions-tags-purchased"]
                                  : item.notes[item.notes.length - 1].type ===
                                    "Reject"
                                  ? styles.statusRejected
                                  : item.notes[item.notes.length - 1].type ===
                                    "Request"
                                  ? styles.statusRequest
                                  : styles.statusPending
                              }`}
                            >
                              {item.notes[item.notes.length - 1].type ===
                              "Purchase"
                                ? "Purchased"
                                : item.notes[item.notes.length - 1].type ===
                                  "Reject"
                                ? "Rejected"
                                : item.notes[item.notes.length - 1].type ===
                                  "Request"
                                ? "Requested"
                                : "Pending"}
                            </span>
                          ) : (
                            <span
                              className={`${styles.status} ${styles.statusNoQuery}`}
                            >
                              No Query
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {formatDate(item.date)}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.category}
                        </span>
                      </td>
                      {!isSales && (
                        <td>
                          <span className={`${styles["col-value"]}`}>
                            {item.supplierName}
                          </span>
                        </td>
                      )}
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.sCode}
                        </span>
                      </td>

                      {/* <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.mfCode}
                        </span> 
                      </td> */}

                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.itemName}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.pack}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.size}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {getAbvValue(item?.abv)}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.giftBoxOrNoGiftBox}
                        </span>
                      </td>

                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.refNref}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.codedOrDecoded}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {getNumbericValue(item.btlsAvail)}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {getNumbericValue(item.csAvail)}
                        </span>
                      </td>

                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {getEuroValue(
                            getNumbericValue(item.pricePerCaseEuro),
                            item.currency,
                            conversionRate
                          )}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {getDollarValue(
                            getNumbericValue(item.pricePerCaseEuro),
                            item.currency,
                            conversionRate
                          )}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {getPoundValue(
                            getNumbericValue(item.pricePerCaseEuro),
                            item.currency,
                            conversionRate
                          )}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {getEuroValue(
                            getNumbericValue(item.pricePerBottleEuro),
                            item.currency,
                            conversionRate
                          )}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {getDollarValue(
                            getNumbericValue(item.pricePerBottleEuro),
                            item.currency,
                            conversionRate
                          )}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {getPoundValue(
                            getNumbericValue(item.pricePerBottleEuro),
                            item.currency,
                            conversionRate
                          )}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.status}
                        </span>
                      </td>

                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.leadTime}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.location}
                        </span>
                      </td>
                      {/* <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.onFloor}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {item.incoterms}
                        </span>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              style={{ bottom: "-70px" }}
              className="position-absolute w-100"
            >
              <Paginate
                currentPage={page}
                pageInfo={pageInfo}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                handlePerPage={handlePerPage}
                resetPage={handleResetPage}
              />
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default SupplierDetail;
