import { useState, useEffect } from 'react';

const useViewportHeight = (percentage = 100) => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    // Update the height on load and resize
    window.addEventListener('resize', updateHeight);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  // Calculate the percentage of the viewport height
  const percentageHeight = (viewportHeight * percentage) / 100;

  return { viewportHeight, percentageHeight };
};

export default useViewportHeight;
