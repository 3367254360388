import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import _ from "lodash";
import { ChevronDown, Search } from "lucide-react";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "24px", // Decrease this value to reduce the height
    height: "24px", // Ensure the height is consistently set
    borderColor: state.isFocused ? "#ccc" : "#ccc",
    boxShadow: state.isFocused ? null : null,
    fontSize: "11px", // Adjust font size here
    "&:hover": {
      borderColor: "#ccc",
    },
  }),
  SingleValue: (provided) => ({
    ...provided,
    fontSize: "11px", // Adjust font size here
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: "0 8px",
    height: "24px",
    fontSize: "11px", // Adjust font size here
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
    padding: "0",
    fontSize: "11px", // Adjust font size here
    width: "50px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "24px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#ccc" : "#ccc",
    "&:hover": {
      color: "#ccc",
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: "105px", // Fixed width of the dropdown menu
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "300px", // Fixed height of the dropdown menu
    overflowY: "auto", // Enable scrolling if the content exceeds the max height
  }),
};

const customDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown size={16} style={{ color: "#aaa" }} />{"| "}
      <Search size={16} style={{ color: "#aaa" }} />
      
      {/* Replace arrow with search icon */}
    </components.DropdownIndicator>
  );
};

const SelectComponent = ({
  inputColumn,
  options,
  placeholder,
  handleValueChange,
  getSuggestionData,
  clearValueHandler,
  onFocusHandler,
  loadingSuggestion,
  isDisabled,
  value,
  isMulti = true,
  isClearable = true,
  selectedFilter = null,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (!options) setSelectedOption(null);

    if (typeof options === "object" && options.length < 1)
      setSelectedOption(null);

    if (value) setSelectedOption({ label: value, value: value });
  }, [options, value]);

  useEffect(() => {
    if (selectedFilter && selectedFilter !== inputColumn) {
      setSelectedOption(null);
    }
  }, [selectedFilter]);
  // useEffect(() => {
  //   if (loadingSuggestion) {
  //     setSelectedOption([{ value: "loading", label: "loading..." }]);
  //   } else if (!loadingSuggestion && options && options.length < 0) {
  //     setSelectedOption(null);
  //   }
  // }, [loadingSuggestion]);

  const handleChange = (option) => {
    if (!option) {
      setSelectedOption(null);
      handleValueChange(null, null);
      return;
    }

    if (typeof options === "object" && options.length < 1) {
      setSelectedOption(null);
      handleValueChange(null, null);
      return;
    }

    setSelectedOption(option);
    handleValueChange(option, inputColumn);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleInputChange = debounce((inputValue) => {
    // Call the API with the input value
    if (!_.isEmpty(inputValue) && !_.isEmpty(inputColumn)) {
      getSuggestionData(inputColumn, inputValue);
    }
  }, 1000); // Adjust the delay as needed (e.g., 1000 ms)

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      placeholder={placeholder}
      styles={customStyles}
      onFocus={() => onFocusHandler(inputColumn)}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={loadingSuggestion}
      isMulti={isMulti}
      components={{ DropdownIndicator: customDropdownIndicator }} // Use custom dropdown indicator
    />
  );
};

export default SelectComponent;
