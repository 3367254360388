import { useState, useEffect } from "react";

export default function useDebounce(callback, delay) {
  const [debouncedValue, setDebouncedValue] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(callback());
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [callback, delay]);

  return debouncedValue;
}
