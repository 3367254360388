// Define initial state
const initialState = {
    productCatalog: null,
    dashboard: null,
    supplierInformation: null,
  };
  
  // Define action types
  const PRODUCT_CATALOG = "PRODUCT_CATALOG";
  const DASHBOARD = "DASHBOARD";
  const SUPPLIER_INFORMATION = "SUPPLIER_INFORMATION";
  const CLEAR_STATE = "CLEAR_STATE";
  
  // Define reducer function
  const preserveStateReducer = (state = initialState, action) => {
    switch (action.type) {
      case PRODUCT_CATALOG:
        return {
          ...state,
          productCatalog: action.payload.preserveState,
        };
      case DASHBOARD:
        return {
          ...state,
          dashboard: action.payload.preserveState,
        };
      case SUPPLIER_INFORMATION:
        return {
          ...state,
          supplierInformation: action.payload.preserveState,
        };
    case CLEAR_STATE:
      return {
        productCatalog: null,
        dashboard: null,
        supplierInformation: null,
      };
      default:
        return state;
    }
  };
  
  // Action creators
  const preserveProductCatalog = (payload) => ({
    type: PRODUCT_CATALOG,
    payload: payload,
  });

  const preserveDashboard = (payload) => ({
    type: DASHBOARD,
    payload: payload,
  });

  const preserveSupplierInformation = (payload) => ({
    type: SUPPLIER_INFORMATION,
    payload: payload,
  });

  const clearState = () => ({
    type: CLEAR_STATE,
  });

  
  export { preserveProductCatalog, preserveDashboard, preserveSupplierInformation, clearState };
  export default preserveStateReducer;
  