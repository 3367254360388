import React from "react";
import { LogOut } from "lucide-react";
import { OverlayTrigger } from "react-bootstrap";
import { tooltipLogout } from "../helper/Tooltip";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearState } from "../../_store/reducers/preserveStateReducer";

/**
 * Renders a sign out button
 */
export const SignOutButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      // Clear session storage after successful logout
      localStorage.clear();
      dispatch(clearState());
      // Navigate to the login page
      navigate("/login");
    } catch (error) {
      console.error("Logout failed or popup was closed: ", error);
      // Handle logout failure or popup closure if needed
    }
  };

  return (
    // <Button >Sign Out</Button>
    <OverlayTrigger placement="right" overlay={tooltipLogout}>
      <LogOut onClick={handleLogout} />
    </OverlayTrigger>
    // <DropdownButton
    //   variant="secondary"
    //   className="ml-auto"
    //   drop="start"
    //   title="Sign Out"
    // >
    //   <Dropdown.Item as="button" onClick={() => handleLogout("popup")}>
    //     Sign out using Popup
    //   </Dropdown.Item>
    //   <Dropdown.Item as="button" onClick={() => handleLogout("redirect")}>
    //     Sign out using Redirect
    //   </Dropdown.Item>
    // </DropdownButton>
  );
};
